import api from "../axios_service.js";
export async function getUsuariosPermissoes() {
  const response = await api.get("admin/usuariospermissoes");
  return response.data.userpermissao;
}

export async function getUsuarioPermissoes(id) {
  const response = await api.get(`admin/usuarios/${id}/permissoes`);
  return response.data.user;
}

export function putPermissaoUsuario(id, userPermissao) {
  return api.put(`admin/usuariospermissoes/${id}`, {
    nivel_acesso: userPermissao.nivel_acesso,
    valor: userPermissao.valor,
    operador: userPermissao.operador,
  });
}

export function postPermissaoUsuario(userPermissao, userId, permissaoId) {
  return api.post("admin/usuariospermissoes/add", {
    permissao_id: permissaoId,
    user_id: userId,
    nivel_acesso: userPermissao.nivel_acesso,
    valor: userPermissao.valor,
    operador: userPermissao.operador,
  });
}
