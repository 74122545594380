export default {
  data: () => ({
    bitWise: {
      visualizar: 0,
      adicionar: 0,
      editar: 0,
      deletar: 0,
      imprimir: 0,
      exportar: 0,
      limitar: 0,
      menu: 0,
    },
  }),
  computed: {
    bitWiseTableTotal() {
      return (
        this.$store.state.bitWiseTable.visualizar |
        this.$store.state.bitWiseTable.adicionar |
        this.$store.state.bitWiseTable.editar |
        this.$store.state.bitWiseTable.deletar |
        this.$store.state.bitWiseTable.imprimir |
        this.$store.state.bitWiseTable.exportar |
        this.$store.state.bitWiseTable.limitar |
        this.$store.state.bitWiseTable.menu
      );
    },
  },
  methods: {
    updateBitMasks(total) {
      let tableTotal = this.bitWiseTableTotal;
      let visualizar = this.$store.state.bitWiseTable.visualizar;
      let adicionar = this.$store.state.bitWiseTable.adicionar;
      let editar = this.$store.state.bitWiseTable.editar;
      let deletar = this.$store.state.bitWiseTable.deletar;
      let imprimir = this.$store.state.bitWiseTable.imprimir;
      let exportar = this.$store.state.bitWiseTable.exportar;
      let limitar = this.$store.state.bitWiseTable.limitar;
      let menu = this.$store.state.bitWiseTable.menu;

      if (total & tableTotal) {
        visualizar & total
          ? (this.bitWise.visualizar = 1)
          : (this.bitWise.visualizar = 0);

        adicionar & total
          ? (this.bitWise.adicionar = 2)
          : (this.bitWise.adicionar = 0);

        editar & total ? (this.bitWise.editar = 4) : (this.bitWise.editar = 0);

        deletar & total
          ? (this.bitWise.deletar = 8)
          : (this.bitWise.deletar = 0);

        imprimir & total
          ? (this.bitWise.imprimir = 16)
          : (this.bitWise.imprimir = 0);

        exportar & total
          ? (this.bitWise.exportar = 32)
          : (this.bitWise.exportar = 0);

        limitar & total
          ? (this.bitWise.limitar = 64)
          : (this.bitWise.limitar = 0);

        menu & total ? (this.bitWise.menu = 128) : (this.bitWise.menu = 0);
      } else {
        this.bitWise.visualizar = 0;
        this.bitWise.adicionar = 0;
        this.bitWise.editar = 0;
        this.bitWise.deletar = 0;
        this.bitWise.imprimir = 0;
        this.bitWise.exportar = 0;
        this.bitWise.limitar = 0;
        this.bitWise.menu = 0;
      }
    },
  },
};
